import type { NavigationItemProps } from "@enzymefinance/ui";
import { Navigation } from "@enzymefinance/ui";
import type { LinkProps } from "components/routing/Link";
import { Link } from "components/routing/Link";
import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

export function DepositQueueToolNavigation() {
  const { id: vaultId } = useParams<"id">();
  const routeTo = useMemo(() => `/tools/deposit-queue/vault/${vaultId}`, [vaultId]);

  return (
    <nav aria-label="DepositQueueTool">
      <Navigation direction="horizontal" className="lg:border-base-200 lg:flex-nowrap lg:justify-between lg:border-b-2">
        <DepositQueueToolNavigationItem to={`${routeTo}/requests`}>Requests</DepositQueueToolNavigationItem>
        <DepositQueueToolNavigationItem to={`${routeTo}/managers`}>Managers</DepositQueueToolNavigationItem>
        <DepositQueueToolNavigationItem to={`${routeTo}/configuration`}>Configuration</DepositQueueToolNavigationItem>
      </Navigation>
    </nav>
  );
}

type DepositQueueToolNavigationItemProps = Omit<NavigationItemProps<LinkProps>, "as" | "isCurrent">;

function DepositQueueToolNavigationItem(props: DepositQueueToolNavigationItemProps) {
  const location = useLocation();
  const isCurrent = location.pathname === props.to.toString();

  return <Navigation.Item {...props} as={Link} isCurrent={isCurrent} className="text-center lg:w-full" />;
}
