import type { NavigationItemProps } from "@enzymefinance/ui";
import { Navigation } from "@enzymefinance/ui";
import type { LinkProps } from "components/routing/Link";
import { Link } from "components/routing/Link";
import { useLocation } from "react-router-dom";

import { useVault } from "../../VaultProvider";
import { getPathForLRTBTC } from "./VaultZeroLendNavigation";

type VaultSettingsNavigationItemProps = Omit<NavigationItemProps<LinkProps>, "as" | "isCurrent">;

function VaultZeroLendLRTBTCAaveV3NavigationItem(props: VaultSettingsNavigationItemProps) {
  const location = useLocation();
  const isCurrent = location.pathname === props.to.toString();

  return <Navigation.Item {...props} as={Link} isCurrent={isCurrent} />;
}

export function VaultZeroLendLRTBTCAaveV3Navigation() {
  const { id } = useVault();

  const basePath = getPathForLRTBTC(id);

  return (
    <>
      <nav aria-label="Vault ZeroLend LRT BTC" className="!mt-4">
        <Navigation direction="horizontal" className="margin-0">
          <VaultZeroLendLRTBTCAaveV3NavigationItem to={`${basePath}/supply`}>
            Supply
          </VaultZeroLendLRTBTCAaveV3NavigationItem>
          <VaultZeroLendLRTBTCAaveV3NavigationItem to={`${basePath}/borrow`}>
            Borrow
          </VaultZeroLendLRTBTCAaveV3NavigationItem>
        </Navigation>
      </nav>
    </>
  );
}
