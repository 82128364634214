import { DepositQueueToolNavigation } from "components/tools/depositQueue/DepositQueueNavigation";
import type { DepositQueueFilteredVaultsInterface } from "components/tools/depositQueue/DepositQueueVaultSelection";
import { DepositQueueVaultSelection } from "components/tools/depositQueue/DepositQueueVaultSelection";
import { useState } from "react";
import { useParams } from "react-router-dom";

export function DepositQueueHeader() {
  const { id: vaultId } = useParams<"id">();
  const [depositQueue, setDepositQueue] = useState<DepositQueueFilteredVaultsInterface | null>(null);

  const isNavigationVisible = Boolean(depositQueue?.depositQueueId && vaultId);

  return (
    <>
      <DepositQueueVaultSelection onChangeDepositQueue={setDepositQueue} />
      {isNavigationVisible ? <DepositQueueToolNavigation /> : null}
    </>
  );
}
