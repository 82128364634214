import type { NavigationItemProps } from "@enzymefinance/ui";
import { Navigation } from "@enzymefinance/ui";
import type { LinkProps } from "components/routing/Link";
import { Link } from "components/routing/Link";
import { useVault } from "components/vault/VaultProvider";
import { useLocation } from "react-router-dom";

type VaultSettingsNavigationItemProps = Omit<NavigationItemProps<LinkProps>, "as" | "isCurrent"> & {
  marketName: string;
};

const lrtBtcMarketName = "lrt-btc";
const rwaStablecoinsMarketName = "rwa-stablecoins";

function getPath(id: string, marketName: string) {
  return `/vault/${id}/protocols/zero-lend/${marketName}`;
}

export function getPathForLRTBTC(id: string) {
  return getPath(id, lrtBtcMarketName);
}

export function getPathForRWAStablecoins(id: string) {
  return getPath(id, rwaStablecoinsMarketName);
}

function VaultZeroLendNavigationItem(props: VaultSettingsNavigationItemProps) {
  const location = useLocation();
  const isCurrent = location.pathname.includes(props.marketName);

  return <Navigation.Item {...props} as={Link} isCurrent={isCurrent} />;
}

export function VaultZeroLendNavigation() {
  const { id } = useVault();

  return (
    <>
      <nav aria-label="Vault ZeroLend">
        <Navigation direction="horizontal">
          <VaultZeroLendNavigationItem to={getPathForLRTBTC(id)} marketName={lrtBtcMarketName}>
            LRT BTC
          </VaultZeroLendNavigationItem>
          <VaultZeroLendNavigationItem to={getPathForRWAStablecoins(id)} marketName={rwaStablecoinsMarketName}>
            RWA Stablecoins
          </VaultZeroLendNavigationItem>
        </Navigation>
      </nav>
    </>
  );
}
