import { PageHeading } from "@enzymefinance/ui";
import { LoadingBoundary } from "components/common/LoadingBoundary";
import { PageLayout } from "components/layout/PageLayout";
import { DepositQueueHeader } from "components/tools/depositQueue/DepositQueueHeader";
import { VaultProvider } from "components/vault/VaultProvider";
import { Outlet } from "react-router-dom";

export function DepositQueueToolLayout() {
  return (
    <PageLayout
      header={
        <>
          <PageHeading>Deposit Queue</PageHeading>
          <DepositQueueHeader />
        </>
      }
    >
      <VaultProvider fallback={null}>
        <LoadingBoundary>
          <Outlet />
        </LoadingBoundary>
      </VaultProvider>
    </PageLayout>
  );
}
