import type { Address } from "@enzymefinance/environment";
import { toAddress } from "@enzymefinance/environment";
import { AddressCreatable, BigIntInput, Form, FormErrorMessage, SubmitButton, useForm } from "@enzymefinance/hook-form";
import { NumberInput } from "@enzymefinance/hook-form";
import { SingleAssetDepositQueue } from "@enzymefinance/sdk/Tools";
import { Constants, type Viem } from "@enzymefinance/sdk/Utils";
import { Divider, Modal } from "@enzymefinance/ui";
import { address } from "@enzymefinance/validation";
import { useSigner } from "components/connection/Connection.js";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useMyVaults } from "components/providers/MyVaultsProvider";
import { useNetwork } from "components/providers/NetworkProvider";
import { VaultLabel } from "components/vault/VaultLabel";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { isAddressEqual } from "viem";
import { z } from "zod";

const schema = z.object({
  managers: z.array(address({ caseInsensitive: true })),
  depositorAllowlistId: z
    .bigint()
    .nonnegative()
    .optional()
    .nullable()
    .default(0n)
    .transform((value) => (value === null ? 0n : value)),
  minRequestTime: z.coerce
    .number()
    .nonnegative()
    .optional()
    .transform((value) =>
      value === undefined ? 0n : BigInt(Math.floor(value * Number(Constants.ONE_DAY_IN_SECONDS))),
    ),
  minDepositAssetAmount: z
    .bigint()
    .nonnegative()
    .optional()
    .nullable()
    .default(0n)
    .transform((value) => (value === null ? 0n : value)),
});

interface DepositQueueEnableModalProps {
  close: () => void;
  isOpen: boolean;
  startTransaction: (
    sendFunction: Viem.PopulatedTransaction<any, any>,
    currentSignerAddress: Address,
    toVault: Address,
  ) => void;
}

export function DepositQueueEnableModal({ close, isOpen, startTransaction }: DepositQueueEnableModalProps) {
  const { id: vaultId } = useParams<"id">();

  const [signerAddress] = useSigner();
  const { ownerships } = useMyVaults();
  const { deployment } = useNetwork();
  const { environment } = useGlobals();

  const pickedVault = useMemo(() => {
    return vaultId ? ownerships.find(({ id }) => isAddressEqual(toAddress(id), toAddress(vaultId))) : undefined;
  }, [ownerships, vaultId]);

  const form = useForm({
    defaultValues: {
      managers: [],
      minRequestTime: undefined,
      depositorAllowlistId: undefined,
      minDepositAssetAmount: undefined,
    },
    onSubmit: (values, helpers) => {
      try {
        if (!signerAddress) {
          return helpers.setError("form", {
            message: "Wallet not connected. You must connect your wallet to perform this action.",
          });
        }

        if (vaultId === undefined || pickedVault === undefined) {
          return helpers.setError("form", {
            message: "No vault selected",
          });
        }

        const fn = SingleAssetDepositQueue.deployProxy({
          factory: environment.contracts.SingleAssetDepositQueueFactory,
          vaultProxy: toAddress(vaultId),
          depositAsset: toAddress(pickedVault.comptroller.denomination.id),
          managers: values.managers,
          minRequestTime: values.minRequestTime,
          depositorAllowlistId: values.depositorAllowlistId,
          minDepositAssetAmount: values.minDepositAssetAmount,
        });

        startTransaction(fn, signerAddress, toAddress(vaultId));
      } catch (error) {
        return helpers.setError("form", {
          message: `Error submitting the transaction: ${error.message}`,
        });
      }
    },
    schema,
  });

  const denominationAsset = pickedVault
    ? environment.getAsset(toAddress(pickedVault.comptroller.denomination.id))
    : undefined;

  return (
    <Modal isOpen={isOpen} dismiss={close} title="Enable deposit queue">
      <Form form={form}>
        <Modal.Body className="flex-column space-y-4">
          <div className="flex items-center justify-between space-x-3">
            <p className="text-sm">Vault</p>
            <VaultLabel reverse={true} id={vaultId} deployment={deployment} name={pickedVault?.name} />
          </div>
          <Divider appearance="subtle" />
          <NumberInput
            name="minRequestTime"
            numberFormat={{ currency: "days", maximumFractionDigits: 4 }}
            label="Min request time"
            description="Min request time after request will be cancelable by the depositor"
          />
          <Divider appearance="subtle" />
          <BigIntInput
            name="minDepositAssetAmount"
            decimals={denominationAsset?.decimals}
            numberFormat={{ currency: denominationAsset?.symbol, maximumFractionDigits: 8 }}
            label="Min deposit amount"
          />
          <Divider appearance="subtle" />
          <BigIntInput name="depositorAllowlistId" decimals={0} label="Deposit Allowlist ID" />
          <Divider appearance="subtle" />

          <div className="space-y-3">
            <p className="text-sm">Deposit Queue manager wallet addresses</p>
            <AddressCreatable isMulti={true} name="managers" label="Set addresses" labelHidden={true} />
          </div>
          <FormErrorMessage />
        </Modal.Body>
        <Modal.Actions>
          <SubmitButton disabled={!vaultId}>Enable</SubmitButton>
        </Modal.Actions>
      </Form>
    </Modal>
  );
}
