import { LoadingBoundary } from "components/common/LoadingBoundary";
import { ProtocolBanner } from "components/vault/protocols/ProtocolBanner";
import { VaultZeroLendNavigation } from "components/vault/protocols/zero-lend/VaultZeroLendNavigation";
import { Outlet } from "react-router";

export function VaultZeroLendLayout() {
  return (
    <>
      <ProtocolBanner integrationId="zero-lend" />
      <VaultZeroLendNavigation />
      <LoadingBoundary>
        <Outlet />
      </LoadingBoundary>
    </>
  );
}
