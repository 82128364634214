import { LoadingBoundary } from "components/common/LoadingBoundary";
import { Outlet } from "react-router";
import { VaultZeroLendLRTBTCAaveV3Navigation } from "./VaultZeroLendLRTBTCAaveV3Navigation";

export function VaultZeroLendLRTBTCAaveV3Layout() {
  return (
    <>
      <VaultZeroLendLRTBTCAaveV3Navigation />
      <LoadingBoundary>
        <Outlet />
      </LoadingBoundary>
    </>
  );
}
