import { LoadingBoundary } from "components/common/LoadingBoundary";
import { Outlet } from "react-router";
import { VaultZeroLendRWAStablecoinsAaveV3Navigation } from "./VaultZeroLendRWAStablecoinsAaveV3Navigation";

export function VaultZeroLendRWAStablecoinsAaveV3Layout() {
  return (
    <>
      <VaultZeroLendRWAStablecoinsAaveV3Navigation />
      <LoadingBoundary>
        <Outlet />
      </LoadingBoundary>
    </>
  );
}
