import type { Address } from "@enzymefinance/environment";
import { toAddress } from "@enzymefinance/environment";
import { ChevronDownIcon } from "@enzymefinance/icons/solid";
import { Utils } from "@enzymefinance/sdk";
import type { Viem } from "@enzymefinance/sdk/Utils";
import type { ButtonProps } from "@enzymefinance/ui";
import { Alert, Button, IconLabel, Menu, useBoolean } from "@enzymefinance/ui";
import { useSigner } from "components/connection/Connection.js";
import { useNetwork } from "components/providers/NetworkProvider";
import { InlineLink } from "components/routing/Link";
import { DepositQueueEnableModal } from "components/tools/depositQueue/enable/DepositQueueEnableModal";
import { TransactionModal, useTransactionModal } from "components/transactions/TransactionModal";
import { VaultLabel } from "components/vault/VaultLabel";
import type {
  DepositQueueDetailsFragment,
  DepositQueueManagerInterfaceVaultsQuery,
  VaultListItemFragment,
} from "queries/core";
import { useDepositQueueManagerInterfaceVaultsQuery, useMyDepositQueueVaultsQuery } from "queries/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getDepositQueue } from "utils/depositQueue";
import { isAddressEqual } from "viem";

export interface DepositQueueFilteredVaultsInterface {
  allowedDepositPolicy: boolean;
  depositQueueId: Address | null;
  vault: VaultListItemFragment;
}

interface DepositQueueManagerType {
  vault: VaultListItemFragment;
  allowedDepositRecipientsPolicies: {
    enabled: boolean;
    addressLists: {
      items: string[];
    }[];
  }[];
  singleAssetDepositQueues: DepositQueueDetailsFragment[];
}

interface DepositQueueVaultSelectionProps {
  onChangeDepositQueue: (data: DepositQueueFilteredVaultsInterface) => void;
}

export function DepositQueueVaultSelection({ onChangeDepositQueue }: DepositQueueVaultSelectionProps) {
  const { id: vaultId } = useParams<"id">();
  const [isOpen, open, close] = useBoolean();
  const { deployment } = useNetwork();
  const navigate = useNavigate();
  const [signerAddress] = useSigner();
  const [currentSignerAddress, setCurrentSignerAddress] = useState<Address | undefined>(undefined);
  const transactions = useTransactionModal();

  const [vaults, setVaults] = useState<DepositQueueFilteredVaultsInterface[]>([]);

  const myDepositQueueVaultsQuery = useMyDepositQueueVaultsQuery({
    variables: signerAddress
      ? {
          accountId: signerAddress,
        }
      : undefined,
  });

  const managedVaults = useMemo(
    () => myDepositQueueVaultsQuery.data?.account?.singleAssetDepositQueueManagements.map(({ vault }) => vault) ?? [],
    [myDepositQueueVaultsQuery.data?.account?.singleAssetDepositQueueManagements],
  );

  const ownerships = useMemo(
    () => myDepositQueueVaultsQuery.data?.account?.ownerships ?? [],
    [myDepositQueueVaultsQuery.data?.account?.ownerships],
  );

  const myVaults = useMemo(
    () => [...new Map([...managedVaults, ...ownerships].map((item) => [item.id, item])).values()],
    [managedVaults, ownerships],
  );

  useDepositQueueManagerInterfaceVaultsQuery({
    variables: {
      vaultIds: myVaults.map((vault) => vault.id),
      comptrollerIds: myVaults.map((vault) => vault.comptroller.id),
    },
    skip: !myVaults.length, // Skip the query if ownerships are empty
    onCompleted: (data) => {
      const resp = processManagerInterfaceVaultsData(data);

      setVaults(resp);
      handleVaultSelection(resp);
    },
  });

  const selectVault = useCallback(
    (data: DepositQueueFilteredVaultsInterface) => {
      onChangeDepositQueue(data);

      if (data.depositQueueId) {
        navigate(`vault/${data.vault.id}/requests`);
      } else {
        navigate(`vault/${data.vault.id}`);
      }
    },
    [navigate, onChangeDepositQueue],
  );

  const handleVaultSelection = useCallback(
    (data: DepositQueueFilteredVaultsInterface[]) => {
      if (data.length > 0 && vaultId) {
        const selectedVault = data.find(({ vault }) => isAddressEqual(toAddress(vault.id), toAddress(vaultId)));

        if (selectedVault) {
          selectVault(selectedVault);
        } else {
          navigate("/tools/deposit-queue");
        }
      }
    },
    [navigate, selectVault, vaultId],
  );

  const filterVaultsWithDepositQueue = useCallback((data: DepositQueueManagerType) => {
    const { allowedDepositRecipientsPolicies, singleAssetDepositQueues, vault } = data;

    if (!allowedDepositRecipientsPolicies[0]) {
      return {
        allowedDepositPolicy: false,
        depositQueueId: singleAssetDepositQueues[0]?.id ? toAddress(singleAssetDepositQueues[0].id) : null,
        vault,
      };
    }

    const depositQueue = getDepositQueue({
      singleAssetDepositQueues,
      allowedDepositRecipientsPolicy: allowedDepositRecipientsPolicies[0],
    });

    return {
      allowedDepositPolicy: !!depositQueue,
      depositQueueId: singleAssetDepositQueues[0]?.id ? toAddress(singleAssetDepositQueues[0].id) : null,
      vault,
    };
  }, []);

  const processManagerInterfaceVaultsData = useCallback(
    (data: DepositQueueManagerInterfaceVaultsQuery | undefined) =>
      myVaults.map((vault) => {
        const allowedDepositRecipientsPolicies =
          data?.allowedDepositRecipientsPolicies.filter((policy) =>
            Utils.Address.safeSameAddress(policy.comptroller.vault?.id, vault.id),
          ) ?? [];

        const singleAssetDepositQueues =
          data?.singleAssetDepositQueues.filter((depositQueue) =>
            isAddressEqual(toAddress(depositQueue.vault.id), toAddress(vault.id)),
          ) ?? [];

        return filterVaultsWithDepositQueue({
          allowedDepositRecipientsPolicies,
          singleAssetDepositQueues,
          vault,
        });
      }),
    [filterVaultsWithDepositQueue, myVaults],
  );

  const startTransaction = useCallback(
    (sendFunction: Viem.PopulatedTransaction<any, any>, currentSignerAddress: Address, toVault: Address) => {
      transactions.start(sendFunction, currentSignerAddress, toVault);
      close();
    },
    [transactions, close],
  );

  useEffect(() => {
    if (Boolean(signerAddress) && !currentSignerAddress) {
      setCurrentSignerAddress(signerAddress);
    }

    if (signerAddress && currentSignerAddress && !isAddressEqual(signerAddress, currentSignerAddress)) {
      navigate("/");
    }
  }, [signerAddress, currentSignerAddress, navigate]);

  const selectedVault = useMemo(() => {
    return vaults.find(({ vault }) => Utils.Address.safeSameAddress(vault.id, vaultId));
  }, [vaultId, vaults]);

  const isDepositQueueEnabled = useMemo(() => {
    return !!selectedVault?.depositQueueId;
  }, [selectedVault]);

  const isAllowedDepositPolicy = useMemo(() => {
    return !!selectedVault?.allowedDepositPolicy;
  }, [selectedVault?.allowedDepositPolicy]);

  if (vaults.length === 0) {
    return <p>No vaults</p>;
  }

  return (
    <>
      <TransactionModal {...transactions} />
      <div className="bg-base-200 p-4 sm:rounded-2xl md:flex md:flex-1 md:items-center md:justify-between">
        <Menu>
          <Menu.Button<ButtonProps>
            as={Button}
            className="bg-base-300 block w-full sm:inline-block sm:w-auto"
            appearance="tertiary"
            size="xl"
            trailingIcon={ChevronDownIcon}
          >
            {selectedVault ? (
              <VaultLabel
                id={selectedVault.vault.id}
                deployment={deployment}
                name={selectedVault.vault.name}
                className="w-full"
              />
            ) : (
              <IconLabel aria-hidden={true} size="xl" space={3} title="Select Vault" className="w-full" />
            )}
          </Menu.Button>
          <Menu.Items>
            <Menu.Group>
              {vaults.map(({ vault, depositQueueId, allowedDepositPolicy }) => (
                <Menu.Item
                  as="button"
                  key={vault.id}
                  onClick={() =>
                    selectVault({
                      vault,
                      depositQueueId,
                      allowedDepositPolicy,
                    })
                  }
                  className="hover:bg-base-100"
                >
                  <VaultLabel id={vault.id} deployment={deployment} name={vault.name} space={3} lines={2} />
                </Menu.Item>
              ))}
            </Menu.Group>
          </Menu.Items>
        </Menu>
        {isDepositQueueEnabled ? null : (
          <Button onClick={open} disabled={!selectedVault} className="my-2 block w-full sm:inline-block sm:w-auto">
            Enable deposit queue
          </Button>
        )}
        <DepositQueueEnableModal
          isOpen={isOpen}
          close={close}
          startTransaction={startTransaction}
          key={`deposit-queue-enable-${isOpen}`}
        />
      </div>
      {!isAllowedDepositPolicy && isDepositQueueEnabled ? (
        <Alert appearance="info">
          {`To finish setting up the Deposit Queue for the ${selectedVault?.vault.name}, add the Deposit Queue contract
          address ${selectedVault?.depositQueueId} to the policy "Limit Wallets Permitted To Deposit". To change the policy, go`}{" "}
          <InlineLink to={`/vault/${selectedVault?.vault.id}/settings/policies`}>here</InlineLink>
        </Alert>
      ) : null}
    </>
  );
}
