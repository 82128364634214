import type { NavigationItemProps } from "@enzymefinance/ui";
import { Navigation } from "@enzymefinance/ui";
import type { LinkProps } from "components/routing/Link";
import { Link } from "components/routing/Link";
import { useLocation } from "react-router-dom";

import { useVault } from "../../VaultProvider";
import { getPathForRWAStablecoins } from "./VaultZeroLendNavigation";

type VaultSettingsNavigationItemProps = Omit<NavigationItemProps<LinkProps>, "as" | "isCurrent">;

function VaultZeroLendRWAStablecoinsAaveV3NavigationItem(props: VaultSettingsNavigationItemProps) {
  const location = useLocation();
  const isCurrent = location.pathname === props.to.toString();

  return <Navigation.Item {...props} as={Link} isCurrent={isCurrent} />;
}

export function VaultZeroLendRWAStablecoinsAaveV3Navigation() {
  const { id } = useVault();

  const basePath = getPathForRWAStablecoins(id);

  return (
    <>
      <nav aria-label="Vault ZeroLend RWA Stablecoins" className="!mt-4">
        <Navigation direction="horizontal">
          <VaultZeroLendRWAStablecoinsAaveV3NavigationItem to={`${basePath}/supply`}>
            Supply
          </VaultZeroLendRWAStablecoinsAaveV3NavigationItem>
          <VaultZeroLendRWAStablecoinsAaveV3NavigationItem to={`${basePath}/borrow`}>
            Borrow
          </VaultZeroLendRWAStablecoinsAaveV3NavigationItem>
        </Navigation>
      </nav>
    </>
  );
}
